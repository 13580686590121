import * as React from "react"
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Box,
  Divider,
  Flex,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react"
import { ChevronRightIcon } from "@chakra-ui/icons"
import { graphql, Link as GatsbyLink, PageProps } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import { IClientStory } from "../components/clientStories"
import { IMarkdownDescription, IQueriedGatsbyImage } from "../components/utils"
import Layout from "../components/layout"
import { ScrollDownButton } from "../components/ui"
import SEO from "../components/CoreUI/SEO"

interface TemplateProps extends PageProps {
  data: {
    story: IClientStory
  }
}

interface SectionProps {
  heading: string
  markdown: IMarkdownDescription
}

interface StoryImageProps {
  image: IQueriedGatsbyImage
}

function StorySection({ heading, markdown }: SectionProps): JSX.Element {
  return (
    <Flex
      flexDir={{ base: `column`, md: `row` }}
      justifyContent="space-between"
      maxW="95vw"
      my={6}
      rounded="lg"
      w="100%"
    >
      <Flex
        align={{ md: `center` }}
        bgGradient={{ md: `linear(40deg, red.600 60%, red.500)` }}
        borderBottomLeftRadius="lg"
        borderTopLeftRadius="lg"
        minH={{ md: `200px` }}
        ml={{ base: `5%`, md: `auto` }}
        w={{ base: `100%`, md: `25%` }}
      >
        <Heading
          as="h2"
          bgClip="text"
          bgGradient={{
            base: `linear(to-r, red.600, red.400)`,
            md: `none`,
          }}
          color={{ base: `transparent`, md: `white` }}
          fontWeight="extrabold"
          px={2}
          textAlign={{ md: `center` }}
          w="100%"
        >
          {heading}
        </Heading>
      </Flex>
      <Box
        borderBottomRightRadius="lg"
        borderColor={{ md: `gray.200` }}
        borderLeft={{ md: 0 }}
        borderTopRightRadius="lg"
        pb={4}
        pt={[6, , 4]}
        w={{ base: `100%`, md: `75%` }}
      >
        <Box
          className="client-story"
          dangerouslySetInnerHTML={{
            __html: markdown.childMarkdownRemark.html,
          }}
          mx="auto"
          px={2}
          w="90%"
        />
      </Box>
    </Flex>
  )
}

function StoryImage({ image }: StoryImageProps): JSX.Element {
  return (
    <Image
      alt={image.description}
      as={GatsbyImage}
      h="50vh"
      image={image.gatsbyImageData}
      maxW="95vw"
      my={4}
      rounded="lg"
      shadow="lg"
    />
  )
}

function ClientStoryTemplate({ data, location }: TemplateProps): JSX.Element {
  const { story } = data

  const storyRef = React.useRef<HTMLDivElement>()

  return (
    <Layout>
      <SEO
        meta={{
          description: story.meetTheClient.childMarkdownRemark.excerpt,
          image: story.featuredImage.gatsbyImageData.images.fallback.src,
          imageAlt: story.featuredImage.description || story.storyTitle,
        }}
        title={`${story.storyTitle} | Sundog Homes`}
        url={location.href}
      />
      <Flex
        align="center"
        flexDir="column"
        h="calc(100vh - 4.5rem)"
        justify="center"
        pos="relative"
        w="100vw"
      >
        <Image
          alt={story.featuredImage.description}
          as={GatsbyImage}
          h="100%"
          image={story.featuredImage.gatsbyImageData}
          left="0"
          style={{ position: `absolute` }}
          top="0"
          w="100%"
          zIndex="-1"
        />
        <Box
          bg="blackAlpha.500"
          bgBlendMode="darken"
          pos="absolute"
          h="100%"
          left="0"
          top="0"
          w="100%"
          zIndex="1"
        />
        <Box color="white" maxW="90vw" textAlign="center" zIndex="1">
          <Heading fontSize={{ base: `4xl`, sm: `5xl` }} fontWeight="extrabold">
            {story.storyTitle}
          </Heading>
          <Box bg="red" h="4px" my={6} />
          <Text fontSize={{ base: `xl`, sm: `2xl` }} fontWeight="light">
            {story.subtitle}
          </Text>
        </Box>
        <Box
          bottom="15%"
          left="calc((100vw - 48px) / 2)"
          pos="absolute"
          textAlign="center"
          w="48px"
          zIndex="1"
        >
          <ScrollDownButton offsetY={72} target={storyRef} />
        </Box>
      </Flex>
      <Box ref={storyRef} w="100vw">
        <Box maxW="95vw" mx="auto" py={4} w="800px">
          <Breadcrumb separator={<ChevronRightIcon />}>
            <BreadcrumbItem>
              <BreadcrumbLink as={GatsbyLink} to="/client-stories/">
                Client Stories
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink
                maxW={{
                  base: `calc(95vw - 140px)`,
                  md: `calc(768px - 140px)`,
                  lg: `calc(800px - 140px)`,
                }}
                overflowX="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {story.storyTitle}
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </Box>
        <Flex
          align="center"
          flexDir="column"
          justify="center"
          maxW="800px"
          mb={16}
          mx="auto"
        >
          {story.meetTheClient && (
            <>
              <StorySection
                heading={`Meet the ${story.clientSurname}s`}
                markdown={story.meetTheClient}
              />
              <Divider my={4} />
            </>
          )}
          {story.whySundog && (
            <StorySection heading="Why Sundog?" markdown={story.whySundog} />
          )}
          {story.supportImage1 && <StoryImage image={story.supportImage1} />}
          {story.thePlan && (
            <>
              <StorySection heading="The Plan" markdown={story.thePlan} />
              <Divider my={4} />
            </>
          )}
          {story.theChallenge && (
            <>
              <StorySection
                heading="The Challenge"
                markdown={story.theChallenge}
              />
              <Divider my={4} />
            </>
          )}
          {story.theSolution && (
            <StorySection heading="The Solution" markdown={story.theSolution} />
          )}
          {story.supportImage2 && <StoryImage image={story.supportImage2} />}
          {story.whatWentWell && (
            <>
              <StorySection
                heading="What Went Well"
                markdown={story.whatWentWell}
              />
              <Divider my={4} />
            </>
          )}
          {story.howsItGoing && (
            <StorySection heading="And Now?" markdown={story.howsItGoing} />
          )}
          {story.supportImage3 && <StoryImage image={story.supportImage3} />}
        </Flex>
      </Box>
    </Layout>
  )
}

export default ClientStoryTemplate

export const query = graphql`
  query($slug: String!) {
    story: contentfulClientStory(slug: { eq: $slug }) {
      ...FullClientStory
    }
  }
`
